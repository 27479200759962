<template>
  <div class="row">
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Ürün Tipi</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.productType }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Ürün Adı</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.name }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Fiyat</div>
      <div class="font-size-lg font-weight-bold">
        {{ selectedItem.price ?? '-' }}
      </div>
    </div>
    <div class="col-6 mb-2">
      <div class="text-dark-50 font-size-sm mb-2">Durum</div>
      <div class="font-size-lg font-weight-bold">
        <div
          v-html="$customFunctions.getIsActiveBadge(selectedItem.isActive)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailView',
  props: {
    selectedItem: String | Object,
  },
};
</script>
